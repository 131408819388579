import { Col, Grid, Row } from 'react-flexbox-grid'
import React from 'react'
import styled from 'styled-components'
import { StBold } from '../components/elements/bold'

const StDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StTitle = styled.h1`
  font-size: 3.25rem;
  margin-bottom: 3rem;
  text-align: center;
`

const listGrid = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
}

export const CONTENT_TEXTBLOCK_WHY_A_SPEAKONF = {
  content: (
    <StDiv>
      <StTitle>Warum eine SpeaKonf?</StTitle>
      <Grid fluid>
      <Row>
          <Col {...listGrid}>
            Mit der <StBold>SpeaKonf</StBold> fördern wir den{' '}
            <StBold>Erfahrungsaustausch</StBold> zwischen Sprecherinnen und
            Sprechern. Denn die <StBold>Skills</StBold>, die man braucht, um vor
            einer Gruppe von Menschen zu sprechen, sind mindestens ebenso
            wichtig wie das <StBold>Fachwissen</StBold>, das hinter jedem{' '}
            <StBold>Vortrag</StBold>, <StBold>Lightning Talk</StBold> 
          </Col>
          <Col {...listGrid}>
            oder einer <StBold>Panel Diskussion</StBold> steht. Wir laden explizit
            Sprecherinnen und Sprecher mit{' '}
            <StBold>unterschiedlichem Erfahrungsstand</StBold> ein, vom{' '}
            <StBold>kompletten Neuling bis zum abgehärteten Vollprofi</StBold>{' '}
            und alles dazwischen. Denn SO entstehen die{' '}
            <StBold>interessantesten Gespräche</StBold>!
          </Col>
        </Row>
      </Grid>
    </StDiv>
  ),
}
