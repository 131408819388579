import { INFO_THEME_IMAGE_BEAMER } from '../components/infoblock'
import { ACTION_SIGNUP } from '../constants/actions'

export const CONTENT_INFOBLOCK_UNKONF_HEROIMAGE = {
  title: `Hallo Speaker!`,
  content:
    'Vorträge, Talks & MeetUps – was sind eure Erfahrungen? Auf der SpeaKonf am 21.03.2025 tauschen wir unsere Erfahrungen aus – sei dabei!',
  theme: INFO_THEME_IMAGE_BEAMER,
  action: ACTION_SIGNUP,
  isHeroImage: true,
}
